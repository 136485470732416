import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Sidebar from './Sidebar';

export class Layout extends Component {

  render () {
    return (
      <React.Fragment>
        <Sidebar />
        <Container>
          {this.props.children}
        </Container>
        <footer className='content-footer'>
          <p className='container-powered'><strong> Powered by &nbsp;</strong>
            <a title='ITFORTAL' href='https://www.itfortal.com/'>
              <img className="img-powered" alt="powered" src="./assets/images/ITFORTAL_R.png" />
            </a>
          </p>
          <p><img className="img-layout" alt="logo" src="./assets/images/ayuntamientoColor.png" /></p>
        </footer>
        
      </React.Fragment>
    );
  }
}

export default Layout;