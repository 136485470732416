import React from 'react';

import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from 'react-icons/fa';
import * as BiIcons from "react-icons/bi";
import * as MdIcons from 'react-icons/md';

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />
  },
  {
    title: 'Usuarios',
    path: '/usuarios',
    icon: <RiIcons.RiUserSettingsFill />,
    roles: 'USER_READ'

  },
  {
    title: 'Apoyos',
    path: '/apoyos',
    icon: <RiIcons.RiHandCoinFill />,
    roles: 'SUPPORT_READ'

  },
  {
    title: 'Directorio',
    path: '/directorio',
    icon: <MdIcons.MdContactPhone />
  },
  {
    title: 'Noticias',
    path: '/noticias',
    icon: <FaIcons.FaNewspaper />
  },
  {
    title: 'Reporte ciudadano',
    path: '/reporte-ciudadano',
    icon: <BsIcons.BsChatSquareTextFill />,
    roles: 'REPORTS_READ'
  },
  {
    title: 'SOS',
    path: '/sos',
    icon: <BiIcons.BiSupport />,
    roles: 'SOS_READ'
  },
  {
    title: 'Configuración',
    path: '/configuracion',
    icon: <BsIcons.BsFillGearFill />
  }
];
