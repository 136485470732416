import http from "./httpService";

const endPoint = "/api/v1/NewsCategory";

export function getNewsCategory(){
    return http.get(endPoint);
}

const newCategoryService = { 
    getNewsCategory
};

export default newCategoryService;