import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import auth from '../services/authService';
import Context from '../context/UserContext';

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #9B0335 ;
    border-left: 4px solid #e1e9fc;
    cursor: pointer;
    color: #e1e9fc;
  }

  &:active {
    color: #e1e9fc;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #E84B7F;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #e1e9fc;
  font-size: 18px;

  &:hover {
    background: #9B0335 ;
    cursor: pointer;
    color: #e1e9fc;
    border-left: 4px solid #e1e9fc;
  }

  &:active {
    color: #e1e9fc;
  }
`;

const SubMenu = ({ item, showSidebar }) => {
  const context = useContext(Context);
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  

  return (
    <React.Fragment>
      <SidebarLink to={item.path} onClick={item.subNav === undefined ? showSidebar :  item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.reduce((p, c, i) => 
           auth.isInRoles(context.user, c.roles) ?
            [...p, <DropdownLink to={c.path} key={i} onClick={showSidebar}>
              {item.icon}
              <SidebarLabel>{c.title}</SidebarLabel>
            </DropdownLink>]
            : []
        )}
    </React.Fragment>
  );
};

export default SubMenu;
