import http from "./httpService";

const endPoint = "/api/v1/Contact";

export function createContact(request){
    return http.post(endPoint, request);
}

export function deleteContact(id){
    return http.delete(`${endPoint}/${id}`);
}

export function getContact(id){
    return http.get(`${endPoint}/${id}`);
}

export function getAllContact(){
    return http.get(endPoint);
}

export function updateContact(id, request){
    return http.put(`${endPoint}/${id}`, request);
}

const contractService = { 
    createContact,
    deleteContact,
    getContact,
    getAllContact,
    updateContact
};

export default contractService;