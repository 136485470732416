import React, { useEffect, useState, useContext} from 'react';
import { Table, Button, Row, Col, notification, Tooltip, Popconfirm } from 'antd';
import { FaPlusCircle, FaPencilAlt, FaUserLock, FaUserTimes } from "react-icons/fa";
import { getAllUser, deleteUser } from './../services/usersService';
import { getAllRoles } from '../services/RolesService';
import auth from "../services/authService";
import Context from '../context/UserContext';

import UsuarioModalNew from './UsuarioModalNew';
import UsuarioModalResetPass from './UsuarioModalResetPass';
import UsuarioModalEdit from './UsuarioModalEdit';


function Usuarios() {
    const context = useContext(Context);
    const [dataUsers, setDataUsers] = useState([]);
    const [dataRoles, setDataRoles] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [modalVisibleNew, setModalVisibleNew] = useState(false);
    const [modalVisibleEdit, setModalVisibleEdit] = useState(false);
    const [modalVisibleResetPass, setModalVisibleResetPass] = useState(false);
    const [nameUser, setNameUser] = useState("");
    const [idUser, setIdUser] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [disBtnEdit, setDisBtnEdit] = useState(true);
    const [disBtnUpd, setDisBtnUpd] = useState(true);
    const [disBtnDel, setDisBtnDel] = useState(true);
    const [disBtnNew, setDisBtnNew] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        async function dtUsers(){
            setTableLoading(true);
            try {
                const { data } = await getAllUser();
                setDataUsers(data.users);
            } finally{
                setTableLoading(false);
            }
        }
        async function dtRoles(){
            const { data } = await getAllRoles();
            setDataRoles(data.roles.sort(getSort("name")));
        }
        dtUsers();
        dtRoles();
    },[]);

    useEffect(() => {
        valRoles(context.user);
    },[context.user]);

    const valRoles = (user) => {
        
        setDisBtnEdit(!auth.isInRoles(user,'USER_UPDATE'));
        setDisBtnUpd(!auth.isInRoles(user,'USER_UPDATE'));
        setDisBtnDel(!auth.isInRoles(user,'USER_DELETE'));
        setDisBtnNew(!auth.isInRoles(user,'USER_CREATE'));
    }

    const getSort = (prop) => {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

    const handleClickModalNew = () => {
        setModalVisibleNew(true);
    }
  
    const handleClickModalEdit = (record) => {
        setNameUser(record.username);
        setIdUser(record.id);
        setModalVisibleEdit(true);
        setUserRoles(record.roles);
    }

    const handleOkModalNew = (dataRequest) =>{
        const tempData = [...dataUsers];
        tempData.push(dataRequest);
        setDataUsers(tempData);
        setModalVisibleNew(false);
    }

    const handleCancelModalNew = () =>{
        setModalVisibleNew(false);
    }

    const handleOkModalEdit = (newRoles) =>{
        const tempData = [...dataUsers];
        tempData.find(n => n.id === idUser).roles = newRoles;
        setDataUsers(tempData);
        if(context.user['username'] === nameUser){
            context.user['role'] = newRoles;
            context.handleChangeRoles(context.user);
            valRoles(context.user);
        }
            
        setModalVisibleEdit(false);
    }

    const handleCancelModalEdit = () =>{
        setModalVisibleEdit(false);
    }

    const handleClickModalResetPass = (record) => {
        setNameUser(record.userName);
        setIdUser(record.id);
        setModalVisibleResetPass(true);
    }
  
    const handleCancelModalResetPass = () =>{
        setModalVisibleResetPass(false);
    }

    const handleOkModalResetPass = () =>{
        setModalVisibleResetPass(false);
    }

    const handleClickDeleteUser = async (record) => {
        setConfirmLoading(true);
        try {
            await deleteUser(record.id);   
            const tempData = [...dataUsers]
            const result = tempData.filter(x => x.id !== record.id);
            setDataUsers(result); 
            notification['success']({message: 'Usuarios', description: 'Se eliminó el usuario.', });
        }finally {
            setOpen(false);
            setConfirmLoading(false);
        }
    }

    const showPopconfirm = () => {
        setOpen(true);
    };

    const columns = [
        {
            title: 'Usuario',
            dataIndex: 'username'
        },
        {
            title: '',
            align: 'center',
            render: (value, record) => (
            <React.Fragment>
            <Tooltip title="Editar roles">
                <Button type="primary" shape="circle" 
                onClick={() => handleClickModalEdit(record)} 
                icon={<FaPencilAlt />} 
                disabled={disBtnEdit}/>
            </Tooltip>
            &nbsp;
            <Tooltip title="Cambio contraseña">
                <Button type="primary" shape="circle" 
                onClick={() => handleClickModalResetPass(record)} 
                icon={<FaUserLock/>} 
                disabled={disBtnUpd} />
            </Tooltip>
            &nbsp;
            <Tooltip title="Eliminar usuario">
                <Popconfirm title="¿Deseas eliminar el usuario?" okText="Eliminar" 
                cancelText="Cancelar" onConfirm={() =>handleClickDeleteUser(record)}
                open={open} 
                disabled={disBtnDel}
                okButtonProps={{loading: confirmLoading,}}>
                    <Button type="primary" shape="circle" 
                    onClick={showPopconfirm} 
                    icon={<FaUserTimes/>} 
                    disabled={disBtnDel} />
                </Popconfirm>
            </Tooltip>
            </React.Fragment>)
        },
    ];

    return(
        <div className='main-container'>

            <UsuarioModalNew modalVisibleNew={modalVisibleNew} 
            handleCancelModalNew={handleCancelModalNew}
            handleOkModalNew={handleOkModalNew}
            dataRoles={dataRoles}
            dataUsers={dataUsers}/>

            <UsuarioModalEdit modalVisibleEdit={modalVisibleEdit} 
            handleCancelModalEdit={handleCancelModalEdit}
            handleOkModalEdit={handleOkModalEdit}
            nameUser={nameUser}
            dataRoles={dataRoles}
            idUser={idUser}
            userRoles={userRoles}/>

            <UsuarioModalResetPass modalVisibleResetPass={modalVisibleResetPass} 
            handleCancelModalResetPass={handleCancelModalResetPass}
            handleOkModalResetPass={handleOkModalResetPass}
            idUser={idUser}/>

            <Row>
                <Col span={24}>
                    <h1 className="title">Usuarios</h1>
                    <hr className="hr-title"/>
                </Col>
            </Row>

            <Row className="detail-container">
                <Col span={24}>
                    <div className="div-right">
                        <Button type="primary" icon={<FaPlusCircle/>} shape="round" onClick={handleClickModalNew} disabled={disBtnNew}>
                          &nbsp; Nuevo
                        </Button>
                    </div>
                </Col>
                
                <Col span={24}>
                    <br/>
                    <Table rowKey="id" dataSource={dataUsers} columns={columns} loading={tableLoading}/>
                </Col>
                
            </Row>

        </div>
    );
}

export default Usuarios;