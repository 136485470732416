import 'antd/dist/antd.css';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './antdStyle.css';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "./config.json";

Sentry.init({ ...config.sentry,  integrations: [new BrowserTracing()] });

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const root = createRoot(rootElement);
root.render(<BrowserRouter basename={baseUrl}>
  <App />
</BrowserRouter>);

registerServiceWorker();

