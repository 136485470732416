import React, { useState, useEffect } from 'react'
import { Modal, Input, Row, Col, Skeleton } from 'antd'

import { getPlaceUrl } from '../services/googleMapsService';

const { TextArea } = Input;

const frameStyle = {
    width: '100%',
    borderStyle: 'none',
    height: '250px'
};

function LocationModal(props){
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
    }, [props.id]);
    
    return <Modal title="Ubicación" 
        open={props.visible}
        onCancel={props.onClose}
        footer={null} >
            <Row>
                {props.location && <Col span={24}>
                    <TextArea placeholder="Descripción" rows={4} value={props.location}/>
                </Col>}
                <Col span={24}>
                    <br/>
                    {props.latitude && props.longitude && loading && <Skeleton active={true}/>}
                    {props.latitude && props.longitude && <iframe
                        onLoad={()=> setLoading(false)}
                            style={!loading ? frameStyle : {display: 'none'}}
                            src={getPlaceUrl(props.latitude, props.longitude)}
                            title={"Location"}
                            >
                    </iframe>}
                </Col>
            </Row>
    </Modal>;
}

export default LocationModal;