import React, {useState,useEffect} from 'react'
import {Modal, Row, Col, Button } from 'antd'

import EvidenceImage from './EvidenceImage';

function EvidenceModal(props){
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
    }, [props.id]);

    return(
            <Modal title="Evidencias" 
            open={props.modalVisibleEvidence}
            onOk={props.handleCloseModalEvidence}
            onCancel={props.handleCloseModalEvidence}
            footer={[
                <Button key="submit" type="primary" shape="round" onClick={props.handleCloseModalEvidence}>
                  Ok
                </Button>
              ]}>
                <Row>
                    <Col span={24} className="content-image-viewer">
                    {props.imgEvidence.map((url, indx) => (<EvidenceImage key={indx} indx={indx} 
                        url={url} loading={loading} onLoad={() => setLoading(false)}/>))}
                    </Col>
                </Row>
            </Modal>
    );

}

export default EvidenceModal;