const getBase64 = (blob) => new Promise((resolve, reject) => {
    if (!blob) return resolve(null);

    try {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result));
        reader.readAsDataURL(blob);
    } catch(e) {
        reject(e);
    }
});

const file = { getBase64 };

export default file;