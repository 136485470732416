import React from "react";
import { Image, Skeleton } from 'antd';

function EvidenceImage(props){

    return(
        <div className="image-viewer">
            {props.url && props.loading && <Skeleton active={true}/>}
            {props.url && <Image onLoad={props.onLoad} width={200} 
                key={props.indx} alt={props.indx} src={props.url} 
                style={!props.loading ? {} : {display: 'none'}}/>}
        </div>
    )
}

export default EvidenceImage;