import axios from "axios";

import config from "../config.json";

export const GEO_STATUS = {
    OK: "OK",
    DENIED: "REQUEST_DENIED",
    ZERO: "ZERO_RESULTS"
}

export function getGeoCode(address) {
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${config.google.maps.apiKey}`);
}

export function getPlaceUrl(latitude, longitude){
    return `https://www.google.com/maps/embed/v1/place?key=${config.google.maps.apiKey}&q=${latitude},${longitude}`;
}

const googleMapsService = {
    getGeoCode,
    getPlaceUrl
}

export default googleMapsService;