import React, { useEffect, useState, useContext} from 'react';

import { Button,
    Col,
    notification,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip } from 'antd';
import { FaPlusCircle, FaPencilAlt, FaTrash} from "react-icons/fa";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

import config from '../config.json'
import Context from '../context/UserContext';
import auth from "../services/authService";
import { getAllNews, deleteNews } from '../services/newsService';
import dateUtils from '../utils/date';
import NewsModal from './NewsModal';

function News() {
    const context = useContext(Context);
    const [dataNews, setDataNews] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [disBtnEdit, setDisBtnEdit] = useState(true);
    const [disBtnDel, setDisBtnDel] = useState(true);
    const [disBtnNew, setDisBtnNew] = useState(true);
    const [nbDisable, setnbDisable] = useState(true);
    const [nnDisable, setnnDisable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [flgEnd, setFlgEnd] = useState(false);
    const [news, setNews] = useState(null);

    useEffect(() => {
        async function dTable(){
            setTableLoading(true);
            try {
                const { data } = await getAllNews(10, 0);
                setDataNews(data.news);
                if(data.news.length < 10){
                    setnnDisable(true);
                    setFlgEnd(true);
                }
                let arr = [{'page':1, 'data':data.news}];
                setDataHistory(arr);
            } finally{
                setTableLoading(false);
            }
        }
        dTable();
    },[]);

    useEffect(() => {
        setDisBtnEdit(!auth.isInRoles(context.user,'NEWS_UPDATE'));
        setDisBtnDel(!auth.isInRoles(context.user,'NEWS_DELETE'));
        setDisBtnNew(!auth.isInRoles(context.user,'NEWS_CREATE'));
    }, [context.user]);

    const fetchTable = async (currentPage) => {
        setTableLoading(true);
        const numLength = dataHistory.reduce((previousValue, currentValue) => previousValue + currentValue.data.length, 0);
        const history = dataHistory.find(d => d.page === currentPage);
        if(!history && !flgEnd){
            const { data } = await getAllNews(10,numLength);
            if(data.news.length < 10){
                setnnDisable(true);
                setFlgEnd(true);
            }
            if(data.news.length === 0){
                setCurrentPage(currentPage-1);
                notification['success']({message: 'Noticias', description: 'No hay más registros.', });
            }
            
            if(data.news.length > 0){
                let arr = {'page':currentPage,'data':data.news};
                const temp = [...dataHistory];
                temp.push(arr);
                setDataHistory(temp);
                setDataNews(data.news);
            }
            
        } else{ 
            if((dataHistory.length === currentPage  && flgEnd) || (history.data.length < 10))
                setnnDisable(true);
                setDataNews(history.data);
        }
        setTableLoading(false);
    }

    const handleClickModalNew = () => {
        setNews(null);
        setModalVisible(true);
    }
  
    const handleClickModalEdit = (record) => {
        setNews(record);
        setModalVisible(true);
    }

    const handleClickDelete = async ({ id }) => {
        // Optimistic approach: Delete from UI first and then call api
        const original = [...dataNews];
        setDataNews(dataNews.filter(x => x.id !== id)); 

        try {
            await deleteNews(id);   
        } catch {
            setDataNews(original);
            notification.error({ message: 'Noticias', description: 'No se pudo eliminar la noticia.' });
        }
    }

    const handleNavigateBefore = ()=>{
        let cp = currentPage === 1 ? currentPage : currentPage - 1;
        if(cp === 1)
            setnbDisable(true);
        setnnDisable(false);
        setCurrentPage(cp);
        fetchTable(cp);
    }

    const handleNavigateNext = () => {
        let cp = currentPage + 1;
        setnbDisable(false);
        setCurrentPage(cp)
        fetchTable(cp);
    }

    const columns = [
        { title: 'Título', dataIndex: 'title' },
        { title: 'Descripción', dataIndex: 'description' },
        { title: 'Categoría', dataIndex: 'category' },
        { title: 'Fecha', dataIndex: 'creationDate', render: (value) => dateUtils.fromUTC(value, config.dateFormat) },
        {
            title: '',
            align: 'center',
            render: (_, record) => <Space>
                <Tooltip title="Editar">
                    <Button type="primary" shape="circle" onClick={() => handleClickModalEdit(record)} icon={<FaPencilAlt />} disabled={disBtnEdit}/>
                </Tooltip>
                <Tooltip title="Eliminar">
                    <Popconfirm 
                        title="¿Deseas eliminar la noticia?"
                        okText="Eliminar" 
                        cancelText="Cancelar"
                        onConfirm={() => handleClickDelete(record)}
                        disabled={disBtnDel}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<FaTrash />}
                            disabled={disBtnDel} />
                    </Popconfirm>
                </Tooltip>
            </Space>
        }
    ];

    const handleOk = (item) => {
        const tempData = [...dataNews];

        // Editar noticia existente
        if(news) {
            const indx = tempData.findIndex(n => n.id === item.id);
            tempData[indx] = {
                // Mantiene todas las propiedades originales excepto las que a continuacion se reemplazan.
                // Las imagenes (url) se reemplazan en los eventos onImageAdd y onImageRemove
                ...tempData[indx],
                title: item.title,
                description: item.description,
                categoryId: item.categoryId,
                category: item.category
            };
        } else {
            // Agregar nueva noticia
            const tempDataH = [...dataHistory];
            tempDataH.find(d => d.page === currentPage).data.unshift(item);
            tempData.unshift(item);
        }

        setDataNews(tempData);
        setModalVisible(false);
    }

    const handleModalCancel = () => {
        setNews(null);
        setModalVisible(false);
    }

    const handleModalImageAdd = (newsId, images) => {
        const i = dataNews.findIndex(n => n.id === newsId);
        const tmp = [...dataNews];
        tmp[i] = { ...tmp[i], images }
        setDataNews(tmp);
    }

    const handleModalImageRemove = (newsId, imgUrl) => {
        const i = dataNews.findIndex(n => n.id === newsId);
        const tmp = [...dataNews];
        tmp[i] = { 
            ...tmp[i],
            images: tmp[i].images.filter(iu => iu !== imgUrl)
        }
        setDataNews(tmp);
    }

    return <div className='main-container'>
        <NewsModal visible={modalVisible} 
            news={news}
            onOk={handleOk}
            onCancel={handleModalCancel}
            onImageAdd={handleModalImageAdd}
            onImageRemove={handleModalImageRemove} />
        <Row>
            <Col span={24}>
                <h1 className="title">Noticias</h1>
                <hr className="hr-title"/>
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}>
                <div className="div-right">
                    <Button type="primary" icon={<FaPlusCircle/>} shape="round" onClick={handleClickModalNew} disabled={disBtnNew}>
                        &nbsp; Nueva
                    </Button>
                </div>
            </Col>
            <Col span={24}>
                <br/>
                <Table rowKey="id" 
                dataSource={dataNews} 
                columns={columns} 
                loading={tableLoading}
                pagination={{
                    pageSize: 30,
                    hideOnSinglePage:true
                }}/>
                <br/>
                <div className="div-right">
                    <Tooltip title="Anteriores">
                        <Button type="primary" shape="circle" 
                        onClick={() => handleNavigateBefore()} 
                        icon={<MdNavigateBefore />}
                        style={!nbDisable ? {} : {display: 'none'}}/>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Siguientes">
                        <Button type="primary" shape="circle" 
                        onClick={() => handleNavigateNext()} 
                        icon={<MdNavigateNext />}
                        style={!nnDisable ? {} : {display: 'none'}}/>
                    </Tooltip>
                </div>
            </Col>
        </Row>
    </div>;
}

export default News;