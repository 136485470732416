import React, { Component } from 'react';
import { Row, Col } from 'antd';

export default class NotFound extends Component {

  render () {
    return (
      <Row justify="center" className="">
        <Col xs={24} md={12} >
            <div className="hero-text">
              <h4>404</h4>
              <h1>Página no</h1>
              <h1>encontrada...</h1>
              <div>
                <img className="img-notfound" alt="not-found" src="./assets/images/notFound.png" />
              </div>
            </div>
            
        </Col>
    </Row>
    );
  }
}
