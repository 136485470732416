import { Row, Col } from 'antd';

export default function Page({ children, title }) {
    return <div className='main-container'>
        <Row>
            <Col span={24}>
                <h1 className="title">{title}</h1>
                <hr className="hr-title"/>
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}>
                {children}
            </Col>
        </Row>
    </div>;
};