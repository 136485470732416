import React, { useState, useEffect } from 'react';
import { Alert, Form, Input, Modal, notification, Select, Skeleton } from 'antd';

import { createContact, updateContact } from '../services/contactService';
import { getGeoCode, getPlaceUrl, GEO_STATUS } from '../services/googleMapsService';
import { TEN_DIGITS_PATTERN } from './../utils/regex';
import { normalized } from './../utils/string';

const { TextArea } = Input;
const frameStyle = {
    width: '100%',
    borderStyle: 'none',
    height: '250px'
};

function ContactModal(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [loadingMap, setLoadingMap] = useState(false);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        // The component may receive an existing contact for editing
        if (!props.contact) return;
        
        form.setFieldsValue(props.contact);

        setLocation({
            status: GEO_STATUS.OK,
            latitude: props.contact.latitude,
            longitude: props.contact.longitude
        });
    }, [props.contact, form]);

    const handleOk = () => {
        form.validateFields().then(async values => {
            if(!location || !location.latitude) return;
            
            setLoading(true);

            const latitude = location?.latitude;
            const longitude = location?.longitude;
            let response;
            let description;

            try {
                if(props.contact){
                    response = await updateContact(props.contact.id, { ...values, latitude, longitude });
                    description = 'Se actualizó el contacto.';
                } else {
                    response = await createContact({ ...values, latitude, longitude });
                    description = 'Se creó el contacto.';
                }
                
                notification.success({ message: 'Directorio', description });
                props.onOk(response.data);
                form.resetFields();
                setLocation(null);
            } finally {
                setLoading(false);
            }
        }).catch(() => { });
    }

    const handleCancel = () => {
        setLocation(null);
        form.resetFields();
        props.onCancel();
    }

    const handleBlurAddress = async ({ target }) => {
        const address = target.value;

        // If the input value falsy, exclude location search and sets GeoLocation null.
        if (!address) {
            setLocation(null);
            return
        };

        setLoading(true);
        setLoadingMap(true);

        try {
            const { data } = await getGeoCode(address);

            setLocation({
                status: data.status,
                latitude: data.results[0]?.geometry.location.lat,
                longitude: data.results[0]?.geometry.location.lng
            });

            if(data.status === GEO_STATUS.OK){
                // Update address from Google Maps API to prevent misspelling
                form.setFieldValue('address', data.results[0].formatted_address)
            }
        } catch(error) {
            setLocation({ status: GEO_STATUS.DENIED });
        } finally {
            setLoading(false);
            setLoadingMap(false);
        }
    }

    return <Modal
        title={`${props.contact ? "Editar" : "Nuevo"}`}
        open={props.visible}
        confirmLoading={loading}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar">
        <Form form={form} layout="vertical" className='all-width'>
            <Form.Item name="name" label="Nombre"
                rules={[{ required: true, message: 'El Nombre no debe ser vacío' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="address" label="Dirección"
                rules={[{ required: true, message: 'La dirección no debe ser vacía' }]}>
                <TextArea rows={4} onBlur={handleBlurAddress} />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Teléfono"
                rules={[{ pattern: TEN_DIGITS_PATTERN, message: 'El Teléfono debe contener 10 digitos', required: true }]}>
                <Input maxLength={10} placeholder="4317461234" />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
                normalize={value => value.map(v => normalized(v).toLowerCase())}>
                <Select mode="tags" options={props.tags.map(t => ({ value: t, label: t }))} />
            </Form.Item>
            {location && location.status === GEO_STATUS.ZERO && <Alert type="warning" message="No se encontró la dirección." showIcon />}
            {location && location.status === GEO_STATUS.DENIED && <Alert type="error" message="No se pudo obtener la ubicación de la dirección." showIcon />}
        </Form>
        {loadingMap && <Skeleton active />}
        {location && location.status === GEO_STATUS.OK && <iframe
            onLoad={() => setLoading(false)}
            style={!loadingMap ? frameStyle : { display: 'none' }}
            src={getPlaceUrl(location.latitude, location.longitude)}
            title="Location">
        </iframe>}
    </Modal>;
}

export default ContactModal;