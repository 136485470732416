import React from 'react';
import { Navigate  } from 'react-router-dom';

import config from '../../config';
import auth from '../../services/authService';

const ProtectedRoute = ({ roles, children }) => {
    const user = auth.getCurrentUser();

    if (!user) 
        return <Navigate to={config.urls.login} replace />;
    if (roles && !auth.isInRoles(user, roles)) 
        return <Navigate to="/" replace />;
    
    return children ;
}
 
export default ProtectedRoute;