import React, { useEffect, useState, useContext } from 'react';

import { Alert, Button, Table, Tooltip } from 'antd';
import { MdLocationPin } from "react-icons/md";

import config from '../config.json';
import SosContext from '../context/SosContext';
import statuses from '../components/dictionary/sosStatus.json';
import auth from "../services/authService";
import { getAllSos } from '../services/sosService';
import dateUtils from '../utils/date';
import SosStatusSelect from './SosStatusSelect';

const LIMIT = 1000;

const PERMISSIONS = {
    update: auth.isInRole('SOS_UPDATE')
};

// PhoneNumber format. (+52 431 100 01 01)
const phoneFormat = (value) => `${value.slice(-value.length-10, -10)} ${value.slice(-10, -7)} ${value.slice(-7, -4)} ${value.slice(-4)}`;

export default function SosTable({ onLocation }) {
    const sosContext = useContext(SosContext);

    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        phoneNumbers: [],
        categories: [],
        status: []
    });

    const columns = [
        { 
            title: 'Ubicación',
            align: 'center',
            render: (_, record) => <Tooltip title="Ubicación">
                <Button type="primary" shape="circle" onClick={() => onLocation(record)} icon={<MdLocationPin />}/>
            </Tooltip>
        },
        { 
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            filters: filters.phoneNumbers,
            filterSearch: (input, { value }) => value.includes(input.replace(/[ -]/g, '')),
            onFilter: (value, record) => record.phoneNumber === value,
            render: phoneFormat
        },
        {
            title: 'Categoría',
            dataIndex: 'category',
            filters: filters.categories,
            onFilter: (value, record) => record.category && record.category === value
        },
        { 
            title: 'Estatus',
            align: 'center',
            render: (_, record) => <SosStatusSelect sos={record} disabled={!PERMISSIONS.update}/>,
            filters: filters.status,
            onFilter: (value, record) => record.statusId === value
        },
        {
            title: 'Fecha',
            dataIndex: 'creationDate',
            render: value => dateUtils.fromUTC(value, config.dateFormat)
        }
    ];
    
    useEffect(() => {
        async function process(){
            setLoading(true);
            
            try {
                const { data } = await getAllSos(LIMIT, 0);
                sosContext.updateSos(data.sos);
            } finally{
                setLoading(false);
            }
        }

        process();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const tmp = {
            phoneNumbers: getUniques(sosContext.items, "phoneNumber").map(pn => ({ value: pn, text: phoneFormat(pn) })),
            categories: getUniques(sosContext.items, "category")
                            .filter(c => c).map(c => ({ value: c, text: c })),
            status: getUniques(sosContext.items, "statusId")
                        .map(i => ({ value: i, text: statuses.find(s => s.key === i).value }))
        }

        setFilters(tmp);
    }, [sosContext]);

    const getUniques = (elements, prop) => [...new Set(elements.map(i => i[prop]))];

    return <>
        {sosContext.items.length >= LIMIT && <Alert type="warning" style={{ marginBottom: "15px" }} showIcon closable
            message={<>Se muestran los últimos {sosContext.items.length} registros.</>}  />}
        <Table
            rowKey={(record) => record.id}
            dataSource={sosContext.items}
            columns={columns}
            loading={loading}
            pagination={{ hideOnSinglePage: true }} />
    </>
}