import React, { useEffect, useState, useContext} from 'react';
import { Table, Button, Row, Col, Tooltip, notification, Space } from 'antd';
import { FaPlusCircle, FaPencilAlt, FaClipboardList} from "react-icons/fa";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { getAllSupports, getSupportRequest } from '../services/supportService';
import config from '../config.json'
import auth from "../services/authService";
import Context from '../context/UserContext';
import dateUtils from '../utils/date';

import SupportModal from './SupportModal';
import SupportRequestModal from './SupportRequestModal';

function Support(){
    const context = useContext(Context);
    const [dataSupport, setDataSupport] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleSol, setModalVisibleSol] = useState(false);
    const [title, setTitle] = useState("");
    const [id, setId] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [disBtnEdit, setDisBtnEdit] = useState(true);
    const [disBtnNew, setDisBtnNew] = useState(true);
    const [nbDisable, setnbDisable] = useState(true);
    const [nnDisable, setnnDisable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataSR, setDataSR] = useState([]);
    const [flgEnd, setFlgEnd] = useState(false);
    const [support, setSupport] = useState(null);

    useEffect(() => {
        async function dTable(){
            setTableLoading(true);
            try {
                const { data } = await getAllSupports(10, 0);
                setDataSupport(data);
                if(data.length < 10){
                    setnnDisable(true);
                    setFlgEnd(true);
                }
                let arr = [{'page':1, 'data':data}];
                setDataHistory(arr);
            } finally{
                setTableLoading(false);
            }
        }
        dTable();
    },[]);

    useEffect(() => {
        setDisBtnEdit(!auth.isInRoles(context.user,'SUPPORT_UPDATE'));
        setDisBtnNew(!auth.isInRoles(context.user,'SUPPORT_CREATE'));
    }, [context.user]);

    const fetchTable = async (currentPage) => {
        setTableLoading(true);
        const numLength = dataHistory.reduce((previousValue, currentValue) => previousValue + currentValue.data.length, 0);
        const history = dataHistory.find(d => d.page === currentPage);
        if(!history && !flgEnd){
            const { data } = await getAllSupports(10,numLength);
            if(data.length < 10){
                setnnDisable(true);
                setFlgEnd(true);
            }
            if(data.length === 0){
                setCurrentPage(currentPage-1);
                notification['success']({message: 'Apoyos', description: 'No hay más registros.', });
            }
            
            if(data.length > 0){
                let arr = {'page':currentPage,'data':data};
                const temp = [...dataHistory];
                temp.push(arr);
                setDataHistory(temp);
                setDataSupport(data);
            }
            
        } else{ 
            if((dataHistory.length === currentPage  && flgEnd) || (history.data.length < 10))
                setnnDisable(true);
                setDataSupport(history.data);
        }
        setTableLoading(false);
    }

    const handleClickSolicitudes = async (record) => {
        setId(record.id);
        setTitle(record.title);
        const { data } = await getSupportRequest(record.id);
        if(data.length === 0) return notification['success']({message: 'Apoyos', description: 'No hay solicitudes.', });
        
        setDataSR(data);
        setModalVisibleSol(true);
    }

    const handleCloseModalSupportRequest = () => setModalVisibleSol(false);

    const handleClickModalNew = () => {
        setSupport(null);
        setModalVisible(true);
    }
  
    const handleClickModalEdit = (record) => {
        setSupport(record);
        setModalVisible(true);
    }

    const handleOk = (item) => {
        const tempData = [...dataSupport];
        if(support) {
            const indx = tempData.findIndex(n => n.id === item.id);
            tempData[indx] = {
                ...tempData[indx],
                title: item.title,
                description: item.description,
                startDate: item.startDate,
                endDate: item.endDate
            };
            setDataSupport(tempData);
        } else {
            const tempDataH = [...dataHistory];
            tempDataH.find(d => d.page === 1).data.unshift(item);
            if(currentPage === 1){
                tempData.unshift(item);
                setDataSupport(tempData);
            } else {
                setCurrentPage(1);
                setnbDisable(true);
                setnnDisable(false);
                fetchTable(1);
            }
        }
        setModalVisible(false);
    }

    const handleModalCancel = () => {
        setSupport(null);
        setModalVisible(false);
    }

    const handleModalImageAdd = (supportId, images) => {
        const i = dataSupport.findIndex(n => n.id === supportId);
        const tmp = [...dataSupport];
        tmp[i] = { ...tmp[i], images: images }
        setDataSupport(tmp);
    }

    const handleModalImageRemove = (newsId, imgUrl) => {
        const i = dataSupport.findIndex(n => n.id === newsId);
        const tmp = [...dataSupport];
        tmp[i] = { 
            ...tmp[i],
            images: tmp[i].images.filter(iu => iu !== imgUrl)
        }
        setDataSupport(tmp);
    }

    const handleNavigateBefore = () => {
        let cp = currentPage === 1 ? currentPage : currentPage - 1;
        if(cp === 1)
            setnbDisable(true);
        setnnDisable(false);
        setCurrentPage(cp);
        fetchTable(cp);
    }

    const handleNavigateNext = () => {
        let cp = currentPage + 1;
        setnbDisable(false);
        setCurrentPage(cp)
        fetchTable(cp);
    }

    const columns = [
        { title: 'Fecha Creación', dataIndex: 'creationDate', render: (value) => dateUtils.fromUTC(value, config.dateFormat) },
        { title: 'Título', dataIndex: 'title' },
        { title: 'Descripción', dataIndex: 'description' },
        { title: 'Fecha inicio', dataIndex: 'startDate', width: 10, render: (value) => dateUtils.fromUTC(value, "DD/MM/YYYY") },
        { title: 'Fecha fin', dataIndex: 'endDate', width: 10, render: (value) => dateUtils.fromUTC(value, "DD/MM/YYYY") },
        { 
            title: '',
            align: 'center',
            render: (_, record) => <Space>
                <Tooltip title="Editar">
                    <Button type="primary" shape="circle" onClick={() => handleClickModalEdit(record)} icon={<FaPencilAlt />} disabled={disBtnEdit}/>
                </Tooltip>
                <Tooltip title="Solicitudes">
                    <Button type="primary" shape="circle" onClick={() => handleClickSolicitudes(record)} icon={<FaClipboardList/>}/>
                </Tooltip>
            </Space>
        },
    ];

    return <div className='main-container'>
        <SupportRequestModal visible={modalVisibleSol} 
        onClose={handleCloseModalSupportRequest}
        id={id} title={title} dataSR={dataSR}/>
        <SupportModal visible={modalVisible} 
        support={support}
        onOk={handleOk}
        onCancel={handleModalCancel}
        onImageAdd={handleModalImageAdd}
        onImageRemove={handleModalImageRemove} />
        <Row>
            <Col span={24}>
                <h1 className="title">Apoyos</h1>
                <hr className="hr-title"/>
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}>
                <div className="div-right">
                    <Button type="primary" icon={<FaPlusCircle/>} shape="round" onClick={handleClickModalNew} disabled={disBtnNew}>
                        &nbsp; Nuevo
                    </Button>
                </div>
            </Col>
            <Col span={24}>
                <br/>
                <Table rowKey="id" 
                dataSource={dataSupport} 
                columns={columns} 
                loading={tableLoading}
                pagination={{
                    pageSize: 40,
                    hideOnSinglePage:true
                }}/>
                <br/>
                <div className="div-right">
                    <Tooltip title="Anteriores">
                        <Button type="primary" shape="circle" 
                        onClick={() => handleNavigateBefore()} 
                        icon={<MdNavigateBefore />}
                        style={!nbDisable ? {} : {display: 'none'}}/>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Siguientes">
                        <Button type="primary" shape="circle" 
                        onClick={() => handleNavigateNext()} 
                        icon={<MdNavigateNext />}
                        style={!nnDisable ? {} : {display: 'none'}}/>
                    </Tooltip>
                </div>
            </Col>
        </Row>
    </div>
}

export default Support;