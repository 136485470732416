import http from "./httpService";

const endPoint = "/api/v1/News";

export function createNews(request){
    return http.post(endPoint, request);
}

export function deleteNews(id){
    return http.delete(`${endPoint}/${id}`);
}

export function getNews(id){
    return http.get(`${endPoint}/${id}`);
}

export function getAllNews(take, skip, category){
    return http.get(endPoint,{ params: { take: take, skip: skip , category: category } });
}

export function updateNews(id, request){
    return http.put(`${endPoint}/${id}`, request);
}

export function imageCreateNews(id, request){
    return http.post(`${endPoint}/${id}/Images`, request);
}

export function imageDeleteNews(id, idImage){
    return http.delete(`${endPoint}/${id}/Images/${idImage}`);
}

const newsService = { 
    createNews,
    deleteNews,
    getNews,
    getAllNews,
    updateNews,
    imageCreateNews,
    imageDeleteNews
};

export default newsService;