import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { HubConnectionBuilder } from "@microsoft/signalr";

import config from "./config";
import ReportsContext from './context/ReportsContext';
import SosContext from './context/SosContext';
import Context from './context/UserContext';
import ProtectedRoute from './components/common/protectedRoute';
import Contact from './components/Contact';
import Configuration from './components/Configuration';
import Home from './components/Home';
import Layout from "./components/Layout";
import Login from './components/Login';
import News from './components/News';
import NotFound from './components/NotFound';
import ReportCitizen from './components/ReportCitizen';
import Sos from './components/Sos';
import Support from './components/Support';
import Usuarios from './components/Usuarios';
import { getToken } from "./services/tokenService";

import './custom.css'

function App(){
  const [user, setUser] = useState(undefined);
  const [sos, setSos] = useState([]);
  const [reports, setReports] = useState([]);
  const [connection, setConnection] = useState(null);

  useEffect(() =>{
    const token = getToken();
    if(!token) return; 
    setUser(jwt_decode(token));

    const newConnection = new HubConnectionBuilder()
      .withUrl("/app-ws", { accessTokenFactory: () => getToken() })
      .withAutomaticReconnect()
      .build();

      setConnection(newConnection);
  },[]);

  useEffect(() => {
    if (!connection) return;

    connection.start()
      .then(() => {
          // When new SOS...
          connection.on('sos', newSos => {
            setSos(prev => [newSos, ...prev]);
          });

          // When new Report...
          connection.on('report', newReport => {
            setReports(prev => [newReport, ...prev]);
          });
      })
      .catch(e => console.error('Unable to connect to "app-ws" hub.', e));
  }, [connection]);

  const handleChangeRoles = (newUser) => setUser(newUser);
 
  return (
  <Context.Provider value={{user, handleChangeRoles}}>
    <SosContext.Provider value={{ items: sos, updateSos: (items) => setSos([...items]) }}>
      <ReportsContext.Provider value={{ items: reports, updateReports: (items) => setReports([...items]) }}>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Layout><Home/></Layout></ProtectedRoute>}/>
          <Route path="/usuarios" element={<ProtectedRoute roles="USER_READ"><Layout><Usuarios/></Layout></ProtectedRoute>}/>
          <Route path="/noticias" element={<ProtectedRoute><Layout><News/></Layout></ProtectedRoute>}/>
          <Route path="/reporte-ciudadano" element={<ProtectedRoute roles="REPORTS_READ"><Layout><ReportCitizen/></Layout></ProtectedRoute>}/>
          <Route path="/apoyos" element={<ProtectedRoute roles="SUPPORT_READ"><Layout><Support/></Layout></ProtectedRoute>}/>
          <Route path="/sos" element={<ProtectedRoute roles="SOS_READ"><Layout><Sos/></Layout></ProtectedRoute>}/>
          <Route path="/directorio" element={<ProtectedRoute><Layout><Contact/></Layout></ProtectedRoute>}/>
          <Route path="/configuracion" element={<ProtectedRoute><Layout><Configuration /></Layout></ProtectedRoute>}/>
          <Route path={config.urls.login} element={<Login/>} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </ReportsContext.Provider>
    </SosContext.Provider>
  </Context.Provider>
  );
}

export default App;
