import http from "./httpService";

const endPoint = "/api/v1/Sos";

export function getSos(id){
    return http.get(`${endPoint}/${id}`);
}

export function getAllSos(take, skip){
    return http.get(endPoint, { params: { take: take, skip: skip } });
}

export function updateSos(id, request){
    return http.patch(`${endPoint}/${id}`, request);
}

const sosService = { 
    getSos,
    getAllSos,
    updateSos
};

export default sosService;