import React, { useState } from 'react';
import { Row, Col, notification } from 'antd';

import LocationModal from './LocationModal';
import EvidenceModal from './EvidenceModal';
import ReportsTable from './ReportsTable';

function ReportCitizen() {
    const [modals, setModals] = useState({
        evidence: false,
        location: false
    });
    const [selected, setSelected] = useState(null);
 
    const handleLocation = (record) => {
        setSelected(record);
        setModals({ ...modals, location: true });
    }

    const handleLocationClose = () => {
        setSelected(null);
        setModals({ ...modals, location: false });
    };

    const handleEvidence = (record) => {
        if(record.images.length === 0) {
            notification['info']({message: 'Evidencias', description: 'El reporte no contiene evidencias.' });
            return;
        }

        setSelected(record);
        setModals({ ...modals, evidence: true });
    }

    return <div className='main-container'>
        <LocationModal
            visible={modals.location} 
            onClose={handleLocationClose} 
            id={selected && selected.id}
            location={selected && selected.location}
            latitude={selected && selected.latitude}
            longitude={selected && selected.longitude} />
        <EvidenceModal
            modalVisibleEvidence={modals.evidence} 
            handleCloseModalEvidence={() => setModals({ ...modals, evidence: false })}
            imgEvidence={(selected && selected.images) || []}
            id={selected && selected.id} />
        <Row>
            <Col span={24}>
                <h1 className="title">Reporte ciudadano</h1>
                <hr className="hr-title"/>
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}>
                <ReportsTable onEvidence={handleEvidence} onLocation={handleLocation} />
            </Col>
        </Row>
    </div>;
}

export default ReportCitizen;