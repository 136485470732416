const TOKEN_NAME = "token";
const REFRESH_TOKEN_NAME = "refreshToken";

export const clearToken = () => localStorage.removeItem(TOKEN_NAME);

export const clearRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_NAME);

export const getToken = () => localStorage.getItem(TOKEN_NAME);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_NAME);

export const setToken = (token) => localStorage.setItem(TOKEN_NAME, token);

export const setRefreshToken = (refreshToken) => localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);

const tokenService = {
    clearToken,
    clearRefreshToken,
    getToken,
    getRefreshToken,
    setToken,
    setRefreshToken
};

export default tokenService;