import React, { useState } from 'react';
import { Row, Col } from 'antd';

import LocationModal from './LocationModal';
import SosTable from './SosTable';

function Sos() {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleLocation = (record) =>{
        setSelected(record);
        setShowModal(true);
    }

    const handleCloseModal = ()=> {
        setSelected(null);
        setShowModal(false);
    };

    return <div className='main-container'>
        <LocationModal visible={showModal}
            id={selected?.id} onClose={handleCloseModal}
            latitude={selected?.latitude} longitude={selected?.longitude} />
        <Row>
            <Col span={24}>
                <h1 className="title">SOS</h1>
                <hr className="hr-title"/>
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}>
                <SosTable onLocation={handleLocation} />
            </Col>
        </Row>
    </div>
}

export default Sos;