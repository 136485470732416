import React, {useState} from 'react';
import {Button, Tooltip, Image } from 'antd';
import {FaTrash} from "react-icons/fa";

function ImageViewer(props){
    const[loading, setLoading] = useState(false);

    const handleOnDelete = async () =>{
        setLoading(true);
        await props.onDelete();
        setLoading(false);
    }

    return(
        <div className='image-viewer'>
            <Tooltip placement="top" title="Borrar">
                <Button onClick={handleOnDelete} className="btn-delete-img" 
                type="primary" shape="circle" icon={<FaTrash />} 
                loading={loading}/>
            </Tooltip>
            <Image width={100} src={props.src} />

        </div>
    );

}

export default ImageViewer;