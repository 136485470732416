import React, {useState, useEffect} from 'react'
import {Modal, Row, Select, Button, Col, notification } from 'antd'
import { updateRoles } from './../services/usersService';
import descRoles from "../components/dictionary/roles.json";

const { Option } = Select;
function UsuarioModalEdit(props){
    const[loading, setLoading] = useState(false);
    const[dataRoles, setDataRoles] = useState([]);
    const[errorRol, setErrorRol] = useState(null);
    const[selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        setDataRoles(props.dataRoles);
        setSelectedRoles(props.userRoles);
        
    }, [props]);

    const handleOk = async () => {
        if (errorRol) return;

        setLoading(true);

        const request = { "Roles": selectedRoles };
        
        try {
            await updateRoles(props.idUser, request);
            notification['success']({message: 'Usuarios', description: 'Se actualizaron los roles.', });
        } finally {
            props.handleOkModalEdit(selectedRoles);
            setLoading(false);
            setErrorRol(null);
        }
    };

    const handleCancel = () => {
        setSelectedRoles(props.userRoles);
        props.handleCancelModalEdit();
    }

    function handleChangeRoles(value) {
        if(value.length === 0){
            setErrorRol('Debes de seleccionar por lo menos 1 rol.');
        } else {
            setErrorRol(null);
        }
        setSelectedRoles(value);
    }

    return(
            <Modal title="Editar roles de usuario" 
            open={props.modalVisibleEdit}
            confirmLoading={loading}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" shape="round" onClick={handleCancel}>
                  Cancelar
                </Button>,
                <Button key="submit" type="primary" shape="round" onClick={handleOk} loading={loading}>
                  Guardar
                </Button>
              ]}>
                <Row>
                    <Col span={24}>
                        <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Seleccionar rol"
                        value={selectedRoles}
                        onChange={handleChangeRoles}
                        >
                        {dataRoles.map(function(obj, idx){
                            return <Option key={idx} value={obj.name}>{descRoles[obj.name]}</Option>
                        })}
                        </Select>
                        {errorRol && <div className="ant-form-item-explain-error">{errorRol}</div>}
                        <div className="ant-form-item-extra">Debes de seleccionar por lo menos 1 rol de usuario.</div>
                    </Col>
                </Row>
            </Modal>
    );

}

export default UsuarioModalEdit;