import React, {useState, useEffect} from 'react'
import {Modal, Input, Row, Upload, Form, notification, Select, Col } from 'antd';
import { createNews, updateNews, imageDeleteNews, imageCreateNews } from '../services/newsService';
import { getNewsCategory } from '../services/newsCategoryService';
import config from '../config.json';
import ImageViewer from './ImageViewer';
import fileUtils from "../utils/file";

const { TextArea } = Input;
const { Option } = Select;

function NewsModal(props){
    const[form] = Form.useForm();
    const[loading, setLoading] = useState(false);
    const[fileList, setFileList] = useState([]);
    const[categories, setCategories] = useState([]);
    const[images, setImages] = useState([]);

    useEffect(() => {
        async function process(){
            const {data} = await getNewsCategory();
            setCategories(data.categories);
        }
        process();
    }, []);

    useEffect(() => {
        if(!props.news) return;

        let imgArr = props.news.images.map((imgUrl) => {
            let spl = imgUrl.split('/');
            return {id:spl[spl.length-1], url:imgUrl };
        });
        setImages(imgArr);

        form.setFieldsValue(props.news);
        
    }, [props.news, form]);

    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);
            if(props.news){
                try {
                    const { data } = await updateNews(props.news.id, values);
                    notification['success']({message: 'Noticias', description: 'Se actualizó la noticia.', });
                    props.onOk(data);
                    form.resetFields();
                } finally {
                    setLoading(false);
                }
            }else{
                // Lista de base64
                const base64Images = await Promise.all(fileList.map(f => fileUtils.getBase64(f.originFileObj)));

                try {
                    const { data } = await createNews({ ...values, base64Images });
                    form.resetFields();
                    setFileList([]);
                    props.onOk(data);
                    notification['success']({message: 'Noticias', description: 'Se creó la noticia.', });
                } finally {
                    setLoading(false);
                }
            }
        }).catch(() => { });
    };

    const handleDeleteImage = async (idImage, imageUrl) =>{
        await imageDeleteNews(props.news.id, idImage);
        notification['success']({message: 'Noticias', description: 'Se eliminó la foto.', });
        const temp = [...images];
        const result = temp.filter( item => item.id !== idImage);
        setImages(result);
        props.onImageRemove(props.news.id, imageUrl)
    }

    const handleCancel = () => {
        props.onCancel();
        form.resetFields();
        setImages([]);
        setFileList([]);
    }

    const uprops = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: async (newFile ) => {
            // Valida el formato de la imaagen
            if (!config.imgFormats.some(f => f === newFile.type)) {
                notification['error']({message: 'Noticias', description: 'El archivo no es png, jpg, jpeg, tiff o gif.', });
                return Upload.LIST_IGNORE
            }

            if(!props.news) {
                setFileList([...fileList, newFile]);
                return false;
            }

            // Si se va a editar
            // File to base64
            const base64 = await fileUtils.getBase64(newFile);

            try {
                const { data } = await imageCreateNews(props.news.id, { base64Image: base64 });
                notification['success']({ message: 'Noticias', description: 'Se agregó la foto.' });
                let imgArr = data.images.map((imgUrl) => {
                    let spl = imgUrl.split('/');
                    return { id: spl[spl.length-1], url: imgUrl };
                });
                setImages(imgArr);
                setFileList([]);
                props.onImageAdd(data.id, data.images);
            } finally {
                return Upload.LIST_IGNORE
            }
        },
        fileList,
        listType: "picture-card",
        onPreview: async file => {
            let src = file.url;
            if (!src) {
                src = await fileUtils.getBase64(file.originFileObj);
            }
            
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        },
        onChange: ({ fileList: newFileList }) => {
            setFileList(newFileList);
        }
    };
    
    return <Modal 
        title={`${props.news ? "Editar" : "Nueva"} Noticia`} 
        open={props.visible}
        confirmLoading={loading}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar">
        <Form form={form} layout="vertical" className='all-width'>
            <Form.Item name="title" label="Título" rules={[
                { required: true, message: 'El título no debe ser vacío' },
                { max: 128, message: 'El título debe tener como máximo 128 caracteres' }
            ]}>
                <Input placeholder="Título" />
            </Form.Item>
            <Form.Item name="description" label="Descripción"
                rules={[
                    { required: true, message: 'La descripción no debe ser vacía' },
                ]}>
                <TextArea placeholder="Descripción" rows={4} />
            </Form.Item>
            <Form.Item name="categoryId" label="Categoría"
                rules={[ { required: true, message: 'Selecciona una categoría ' } ]}>
                <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Seleccionar categoría"
                >
                {categories.map((obj) => <Option key={obj.id} value={obj.id}>{obj.name}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="images"
                rules={[
                    { required: true, message: 'Se requiere por lo menos 1 foto.' },
                ]}>
                <Upload {...uprops}>
                    {props.news ? images.length < 5 && '+ Foto' : fileList.length < 5 && '+ Foto'}
                </Upload>
            </Form.Item>
        </Form>
        {props.news && <Row>
            <Col span={24}>
                <div className='content-image-viewer'>
                    {images.map((data) => <ImageViewer src={data.url} key={data.id} onDelete={() => handleDeleteImage(data.id, data.url)}/>)}
                </div>
            </Col>
        </Row>}
    </Modal>;
}

export default NewsModal;