import axios from "axios";
import { notification } from "antd";

import config from "../config";
import {
  clearToken,
  clearRefreshToken,
  getToken,
  setToken,
  setRefreshToken, 
  getRefreshToken
} from "./tokenService";

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers["Authorization"] = 'Bearer ' + token;
  }
  return config;
}, null);

instance.interceptors.response.use(null, async (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if(!expectedError){
    notification['error']({message: 'Sistema', description: 'Ha ocurrido un error inesperado!' });
  }

  const originalConfig = error.config;
  // If response is 401 and is not from api login or refresh token
  if (originalConfig.url !== config.urls.api_login && originalConfig.url !== config.urls.api_refresh_token &&
    error.response && error.response.status === 401 &&
    !originalConfig._retry) {

    originalConfig._retry = true;

    try {
      const { data } = await instance.post(config.urls.api_refresh_token, { 
        token: getToken(),
        refreshToken: getRefreshToken()
      });

      setToken(data.token);
      setRefreshToken(data.refreshToken);

      return instance(originalConfig);
    } catch (_error) {
      if(error.response && error.response.status === 401) {
        clearToken();
        clearRefreshToken();
        window.location = config.urls.login;
        return;
      }
      return Promise.reject(_error);
    }
  }

  return Promise.reject(error);
});

export default instance;