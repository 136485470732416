import React from 'react';
import { Modal, Table } from 'antd';
import config from '../config.json';
import dateUtils from '../utils/date';

function SupportRequestModal(props){

    const columns = [
        { title: 'Fecha', dataIndex: 'creationDate', render: (value) => dateUtils.fromUTC(value, config.dateFormat) },
        { title: 'Nombre', dataIndex: 'fullName' },
        { title: 'Fecha de Nacimiento', dataIndex: 'birthday', render: (value) => dateUtils.fromUTC(value, "DD/MM/YYYY") },
        { title: 'Dirección', dataIndex: 'address' },
        { title: 'Teléfono', dataIndex: 'phoneNumber' },
    ];

    return <Modal title={props.title}
        open={props.visible}
        onCancel={props.onClose}
        width={800}
        okText="Ok"
        onOk={props.onClose}
        cancelButtonProps={{ style: { display: 'none' } }}>
            <Table rowKey="id" dataSource={props.dataSR} columns={columns} />
    </Modal>
}

export default SupportRequestModal;