import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import packageInfo from '../../package.json';
import { login } from './../services/authService';

import "../components/Login.css"

function Login(){
    const [loading, setLoading] = useState(false);

    const handleLogin = async ({ username, password}) =>{
        setLoading(true);

        try {
            await login(username, password);
            window.location = "/";
        } catch (error) {
            if(error.response && error.response.status === 401)
                notification["warning"]({ message: "Credenciales", description: "Usuario o contraseña incorrectas." }); 
        }finally{
            setLoading(false);
        }
    };

    return <div className='login-container'>
        <div className='login-box'>
            <img className="login-logo" alt="logo" src="./assets/images/escudoApp.png" />
            <Form onFinish={handleLogin}>
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Escribe tu usuario!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Escribe tu contraseña!' }]}
                >
                    <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Contraseña"
                    />
                </Form.Item>
                <Form.Item >
                    <Button className="btn-submit btn-login" loading={loading} htmlType="submit" size="large" block>
                    Entrar
                    </Button>
                </Form.Item>
            </Form>
            <div className='login-box-bottom'>
                <p>{new Date().getFullYear()} - JalosApp - {`v${packageInfo.version}`}</p>
            </div>
        </div>
    </div>;
}

export default Login;