import React, {useState} from 'react'
import {Modal, Input, Row, Select, Button, Form, notification } from 'antd'
import { PASSWORD_PATTERN, EMAIL_PATTERN} from "../utils/regex";
import { createUser } from './../services/usersService';
import descRoles from "../components/dictionary/roles.json";

const { Option } = Select;
function UsuarioModalNew(props){
    const[form] = Form.useForm();
    const[loading, setLoading] = useState(false);

    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);

            try {
                const { data } = await createUser(values);
                form.resetFields();
                props.handleOkModalNew(data);
                notification['success']({message: 'Usuarios', description: 'Se creó el usuario.', });
            }finally {
                setLoading(false);
            }
        });
    };

    const handleCancel = () => {
        form.resetFields();
        props.handleCancelModalNew();
    }

    const onValidUsername = (value) =>{
        var result = !props.dataUsers.some(u => u.username.toLowerCase() === value.toLowerCase());
        return result;
    }
    
    return(
            <Modal title="Nuevo usuario" 
            open={props.modalVisibleNew}
            confirmLoading={loading}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" shape="round" onClick={handleCancel}>
                  Cancelar
                </Button>,
                <Button key="submit" type="primary" shape="round" onClick={handleOk} loading={loading}>
                  Guardar
                </Button>
              ]}>
                <Row>
                    <Form form={form} layout="vertical">
                        <Form.Item name="userName" extra="Nombre de usuario debe ser una dirección de correo."
                            rules={[
                                { required: true, message: 'Nombre de usuario no debe ser vacío' },
                                { pattern: EMAIL_PATTERN, message: 'Nombre de usuario no es una dirección de correo' },
                                { validator: (_, value) => onValidUsername(value) ? Promise.resolve() : Promise.reject(new Error('Ya existe un usuario con ese nombre')) }
                            ]}>
                            <Input placeholder="Nombre" />
                        </Form.Item>
                        <Form.Item name="password" extra="La contraseña debe tener al menos 8 caracteres y cumplir lo siguiente: mayúsculas, minúsculas, número y carácter especial (!@#$.%&*)."
                            rules={[
                                { required: true, message: 'Contraseña no debe ser vacía' },
                                { pattern: PASSWORD_PATTERN, message: 'La contraseña no cumple con el criterio' }
                            ]}>
                            <Input.Password placeholder="Contraseña" />
                        </Form.Item>
                        <Form.Item name="roles" extra="Debes de seleccionar por lo menos 1 rol de usuario."
                            rules={[
                                { required: true, message: 'Selecciona 1 rol' }
                            ]}>
                            <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Seleccionar rol"
                            >
                            {props.dataRoles.map(function(obj, idx){
                                return <Option key={idx} value={obj.name}>{descRoles[obj.name]}</Option>
                            })}
                            </Select>
                        </Form.Item>
                    </Form>
                </Row>
            </Modal>
    );

}

export default UsuarioModalNew;