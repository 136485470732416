import React, {useState, useEffect} from 'react'
import {Modal, Input, Row, Upload, Form, notification, Col, DatePicker, Spin } from 'antd';
import { createSupport, updateSupport, imageCreateSupport, imageDeleteSupport } from '../services/supportService';
import config from '../config.json';
import fileUtils from "../utils/file";
import moment from 'moment';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';

import ImageViewer from './ImageViewer';


const { TextArea } = Input;

function SupportModal(props){
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [loadingImg, setLoadingImg] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if(!props.support) return;

        let imgArr = props.support.images.map((imgUrl) => {
            let spl = imgUrl.split('/');
            return {id:spl[spl.length-1], url:imgUrl };
        });
        setImages(imgArr);

        form.setFieldsValue({
            title: props.support.title,
            description: props.support.description,
            startDate: moment.utc(props.support.startDate).local(),
            endDate: moment.utc(props.support.endDate).local()
        });
        
    }, [props.support, form]);

    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);
            // Converts start and end date with zero time and then to UTC
            const startDate = moment.utc(values.startDate.startOf('day')).format();
            const endDate = moment.utc(values.endDate.startOf('day')).format();

            if(props.support){
                try {
                    const { data } = await updateSupport(props.support.id, { ...values, startDate, endDate });
                    notification['success']({message: 'Apoyos', description: 'Se actualizó el apoyo.', });
                    props.onOk(data);
                    form.resetFields();
                } finally {
                    setLoading(false);
                }
            }else{
                const base64Images = await Promise.all(fileList.map(f => fileUtils.getBase64(f.originFileObj)));
                try {
                    const { data } = await createSupport({ ...values, base64Images, startDate, endDate });
                    form.resetFields();
                    setFileList([]);
                    props.onOk(data);
                    notification['success']({ message: 'Apoyos', description: 'Se creó el apoyo.' });
                } finally {
                    setLoading(false);
                }
            }
        }).catch(() => { });
    };

    const handleDeleteImage = async (idImage, imageUrl) =>{
        await imageDeleteSupport(props.support.id, idImage);
        notification['success']({ message: 'Apoyos', description: 'Se eliminó la foto.' });
        const temp = [...images];
        const result = temp.filter( item => item.id !== idImage);
        setImages(result);
        props.onImageRemove(props.support.id, imageUrl)
    }

    const handleCancel = () => {
        props.onCancel();
        form.resetFields();
        setImages([]);
        setFileList([]);
    }

    const uprops = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: async (newFile ) => {
            if (!config.imgFormats.some(f => f === newFile.type)) {
                notification['error']({message: 'Apoyos', description: 'El archivo no es png, jpg, jpeg, tiff o gif.', });
                return Upload.LIST_IGNORE
            }
            if(!props.support) {
                setFileList([...fileList, newFile]);
                return false;
            }

            const base64 = await fileUtils.getBase64(newFile);

            try {
                setLoadingImg(true);
                const { data } = await imageCreateSupport(props.support.id, { base64Image: base64 });
                notification['success']({ message: 'Apoyos', description: 'Se agregó la foto.' });
                const tmp = [...images];
                let spl = data.split('/');
                tmp.push({ id: spl[spl.length-1], url: data });
                setImages(tmp);
                setFileList([]);
                props.onImageAdd(data.id, data.images);
            } finally {
                setLoadingImg(false);
                return Upload.LIST_IGNORE
            }
        },
        fileList,
        listType: "picture-card",
        onPreview: async file => {
            let src = file.url;
            if (!src) {
                src = await fileUtils.getBase64(file.originFileObj);
            }
            
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        },
        onChange: ({ fileList: newFileList }) => {
            setFileList(newFileList);
        }
    };
    
    return <Modal 
        title={`${props.support ? "Editar" : "Nuevo"} apoyo`} 
        open={props.visible}
        confirmLoading={loading}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar">
        <Form form={form} layout="vertical" className='all-width'>
            <Form.Item name="title" label="Título"
                rules={[
                    { required: true, message: 'El título no debe ser vacío' },
                    { max: 128, message: 'El título debe tener como máximo 128 caracteres' }
                ]}>
                <Input placeholder="Título" />
            </Form.Item>
            <Form.Item name="description" label="Descripción"
                rules={[
                    { required: true, message: 'La descripción no debe ser vacía' },
                ]}>
                <TextArea placeholder="Descripción" rows={4} />
            </Form.Item>
            <Form.Item name="startDate" label="Fecha inicio"
                rules={[ { required: true, message: 'Selecciona una fecha ' } ]}>
                <DatePicker locale={locale}/>
            </Form.Item>
            <Form.Item name="endDate" label="Fecha fin" dependencies={['startDate']}
                rules={[ { required: true, message: 'Selecciona una fecha ' },
                ({ getFieldValue }) => ({
                    validator(_,value){
                        if((moment(getFieldValue("startDate")).isAfter(moment(value)))) 
                            return Promise.reject("La fecha fin debe ser mayor a la inicio");
                        return Promise.resolve();
                    }
                }) ]}>
                <DatePicker locale={locale}/>
            </Form.Item>
            <Form.Item name="images"
                rules={[
                    () => ({
                        validator(_,value){
                            if((props.support && images.length === 0) || (!props.support && fileList.length === 0)) 
                                return Promise.reject('Se requiere por lo menos 1 foto.');
                            return Promise.resolve();
                        }
                    })
                ]}>
                <Spin spinning={loadingImg}>
                    <Upload {...uprops}>
                        {props.support ? images.length < 5 && '+ Foto' : fileList.length < 5 && '+ Foto'}
                    </Upload>
                </Spin>
            </Form.Item>
        </Form>
        {props.support && <Row>
            <Col span={24}>
                <div className='content-image-viewer'>
                    {images.map((data) => <ImageViewer src={data.url} key={data.id} onDelete={() => handleDeleteImage(data.id, data.url)}/>)}
                </div>
            </Col>
        </Row>}
    </Modal>;
}

export default SupportModal;