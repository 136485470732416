import React, { useEffect, useState, useContext } from 'react';

import { Table, Button, Row, Col, Tooltip, Tag, Input, Popconfirm, Space, notification } from 'antd';
import { FaPlusCircle, FaPencilAlt, FaTrash } from "react-icons/fa";

import { deleteContact, getAllContact } from './../services/contactService';
import auth from "../services/authService";
import Context from '../context/UserContext';
import { normalized } from '../utils/string';

import ContactModal from './ContactModal';

const PERMISSIONS = {
    delete: auth.isInRole('CONTACT_DELETE')
};

function Contact() {
    const context = useContext(Context);
    const [dataContact, setDataContact] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [disBtnEdit, setDisBtnEdit] = useState(true);
    const [disBtnNew, setDisBtnNew] = useState(true);
    const [contact, setContact] = useState(null);
    const [tags, setTags] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [tempDataTable, setTempDataTable] = useState([]);

    useEffect(() => {
        async function dtable() {
            setTableLoading(true);
            try {
                const { data } = await getAllContact();
                setDataContact(data);
                setTempDataTable(data);
                updateTags(data);
            } finally {
                setTableLoading(false);
            }
        }
        dtable();
    }, []);

    useEffect(() => {
        setDisBtnEdit(!auth.isInRoles(context.user, 'CONTACT_UPDATE'));
        setDisBtnNew(!auth.isInRoles(context.user, 'CONTACT_CREATE'));
    }, [context.user]);

    const handleClickModalNew = () => {
        setContact(null);
        setModalVisible(true);
    }

    const handleClickModalEdit = (record) => {
        setContact(record);
        setModalVisible(true);
    }

    const handleOk = (item) => {
        const tempData = [...dataContact];
        if (contact) {
            const indx = tempData.findIndex(n => n.id === item.id);
            tempData[indx] = { ...tempData[indx], ...item };
        } else {
            tempData.unshift(item);
        }

        setDataContact(tempData);
        setTempDataTable(tempData);
        setModalVisible(false);
        updateTags(tempData);
    }

    const handleModalCancel = () => {
        setContact(null);
        setModalVisible(false);
    }

    const handleSearch = (e) => {
        const currValue = e.target.value;
        setSearchValue(currValue);
        if (!currValue)
            return setDataContact(tempDataTable);

        const filteredData = tempDataTable.filter(entry =>
            normalized(entry.name).includes(normalized(currValue)) ||
            normalized(entry.address).includes(normalized(currValue)) ||
            normalized(entry.phoneNumber).includes(normalized(currValue)) ||
            normalized(entry.tags.join('')).includes(normalized(currValue))
        );
        setDataContact(filteredData);
    };

    const handleDelete = async ({ id }) => {
        const original = [...dataContact];
        
        const tmp = original.filter(c => c.id !== id);
        setDataContact(tmp);
        setTempDataTable(tmp);

        try {
            await deleteContact(id);
            updateTags(tmp);
        } catch {
            notification.error({ message: "Directorio", description: "No se pudo eliminar el contacto" })
            setDataContact(original);
            setTempDataTable(original);
        }
    }

    const columns = [
        { title: 'Nombre', dataIndex: 'name' },
        { title: 'Dirección', dataIndex: 'address' },
        { 
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            width: 130
        },
        {
            title: 'Etiquetas',
            dataIndex: 'tags',
            render: value => value && value.map(t => <Tag key={t} color="processing">{t}</Tag>)
        },
        {
            title: '',
            align: 'center',
            width: 80,
            render: (_, record) => <Space>
                <Tooltip title="Editar">
                    <Button
                        type="primary"
                        shape="circle"
                        onClick={() => handleClickModalEdit(record)}
                        icon={<FaPencilAlt />}
                        disabled={disBtnEdit} />
                </Tooltip>
                <Tooltip title="Eliminar">
                    <Popconfirm 
                        title="¿Deseas eliminar el contacto?"
                        okText="Eliminar" 
                        cancelText="Cancelar"
                        onConfirm={() => handleDelete(record)}
                        disabled={!PERMISSIONS.delete}
                        >
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<FaTrash />}
                            disabled={!PERMISSIONS.delete} 
                            />
                    </Popconfirm>
                </Tooltip>
            </Space>
        }
    ];

    // Extracts all unique tags in contacts 
    const updateTags = (contacts) => setTags([...new Set(contacts.reduce((p, c) => [...p, ...(c.tags || [])], []))]);

    return <div className='main-container'>
        <ContactModal
            visible={modalVisible}
            tags={tags}
            contact={contact}
            onOk={handleOk}
            onCancel={handleModalCancel} />
        <Row>
            <Col span={24}>
                <h1 className="title">Directorio</h1>
                <hr className="hr-title" />
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24} style={{ marginBottom: "15px" }}>
                <div className="div-right">
                    <Button type="primary" icon={<FaPlusCircle />} shape="round" onClick={handleClickModalNew} disabled={disBtnNew}>
                        &nbsp; Nuevo
                    </Button>
                </div>
            </Col>
            <Col span={6} offset={18} style={{ marginBottom: "15px" }}>
                <Input placeholder="Buscar" value={searchValue} onChange={handleSearch} />
            </Col>
            <Col span={24}>
                <Table
                    rowKey="id"
                    dataSource={dataContact}
                    columns={columns}
                    loading={tableLoading}
                    pagination={{ hideOnSinglePage: true }} />
            </Col>
        </Row>
    </div>
}

export default Contact;