import http from "./httpService";

const endPoint = "/api/v1/Roles";

export function getAllRoles(){
    return http.get(endPoint);
}

const rolesService = { 
    getAllRoles
};

export default rolesService;