import React, {useState} from 'react'
import {Modal, Input, Row, Button, Form, notification } from 'antd'
import { PASSWORD_PATTERN} from "../utils/regex";
import { resetPassword } from './../services/usersService';

function UsuarioModalResetPass(props){
    const[form] = Form.useForm();
    const[loading, setLoading] = useState(false);

    const handleOk = () => {
        form.validateFields().then( async (values) => {
            setLoading(true);
            const request = {
                "NewPassword": values.password
              };
            
            try {
                await resetPassword(props.idUser, request);
                notification['success']({message: 'Usuarios', description: 'Se actualizó la contraseña del usuario.', });
            }finally {
                form.resetFields();
                setLoading(false);
                props.handleOkModalResetPass(values);
            }
            
        });
    };

    const handleCancel = () => {
        form.resetFields();
        props.handleCancelModalResetPass();
    }
    
    return(
            <Modal title="Cambio de contraseña" 
            open={props.modalVisibleResetPass}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[
                <Button key="back" shape="round" onClick={handleCancel}>
                  Cancelar
                </Button>,
                <Button key="submit" type="primary" shape="round" onClick={handleOk} loading={loading}>
                  Guardar
                </Button>
              ]}>
                <Row>
                    <Form form={form} layout="vertical">
                        <Form.Item name="password" extra="La contraseña debe tener al menos 8 caracteres y cumplir con lo siguiente: mayúsculas, minúsculas, número y carácter especial (!@#$.%&*)."
                            rules={[
                                { required: true, message: 'Contraseña no debe ser vacía' },
                                { pattern: PASSWORD_PATTERN, message: 'La contraseña no cumple con el criterio' }
                            ]}>
                            <Input.Password placeholder="Contraseña" />
                        </Form.Item>
                    </Form>
                </Row>
            </Modal>
    );

}

export default UsuarioModalResetPass;