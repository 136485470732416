import http from "./httpService";

const endPoint = "/api/v1/Support";

export function createSupport(request){
    return http.post(endPoint, request);
}

export function getSupport(id){
    return http.get(`${endPoint}/${id}`);
}

export function getSupportRequest(id){
    return http.get(`${endPoint}/${id}/Request`);
}

export function getAllSupports(take, skip){
    return http.get(endPoint,{ params: { take: take, skip: skip } });
}

export function imageCreateSupport(id, request){
    return http.post(`${endPoint}/${id}/Images`, request);
}

export function imageDeleteSupport(id, idImage){
    return http.delete(`${endPoint}/${id}/Images/${idImage}`);
}

export function updateSupport(id, request){
    return http.put(`${endPoint}/${id}`, request);
}

const supportService = { 
    createSupport,
    getSupport,
    getAllSupports,
    imageCreateSupport,
    imageDeleteSupport,
    updateSupport,
    getSupportRequest
};

export default supportService;