import http from "./httpService";

const endPoint = "/api/v1/Users";

export function createUser(request){
    return http.post(endPoint, request);
}

export function deleteUser(id){
    return http.delete(`${endPoint}/${id}`);
}

export function getUser(id){
    return http.get(`${endPoint}/${id}`);
}

export function getAllUser(){
    return http.get(endPoint);
}

export function resetPassword(id, request){
    return http.patch(`${endPoint}/${id}/ResetPassword`, request);
}

export function updateRoles(id, request){
    return http.put(`${endPoint}/${id}`, request);
}

const usersService = { 
    createUser,
    deleteUser,
    getUser,
    getAllUser,
    resetPassword,
    updateRoles
};

export default usersService;