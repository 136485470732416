import React, { useEffect, useState, useContext } from 'react';

import { Alert, Button, Table, Tooltip, Popconfirm } from 'antd';
import { FaTrash } from "react-icons/fa";
import { IoMdImages } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";

import config from '../config.json';
import ReportsContext from '../context/ReportsContext';
import auth from "../services/authService";
import { deleteReport, getAllReports } from '../services/reportsService';
import dateUtils from '../utils/date';

const LIMIT = 1000;

const PERMISSIONS = {
    delete: auth.isInRole('REPORTS_DELETE')
};

export default function ReportsTable({ onEvidence, onLocation }) {
    const reportsContext = useContext(ReportsContext);

    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ categories: [] });
    
    const columns = [
        { title: 'Título', dataIndex: 'title' },
        { title: 'Descripción', dataIndex: 'description' },
        { 
            title: 'Categoría',
            dataIndex: 'category',
            filters: filters.categories,
            onFilter: (value, record) => record.category && record.category === value
        },
        { 
            title: 'Fecha',
            dataIndex: 'creationDate',
            render: (value) => dateUtils.fromUTC(value, config.dateFormat) 
        },
        {
            title: 'Ubicación',
            align: 'center',
            render: (_, record) => <Tooltip title="Ubicación">
                <Button type="primary" shape="circle" onClick={() => onLocation(record)} icon={<MdLocationPin />} />
            </Tooltip>
        },
        {
            title: 'Evidencias',
            align: 'center',
            render: (_, record) => <Tooltip title="Evidencias">
                <Button type="primary" shape="circle" onClick={() => onEvidence(record)} icon={<IoMdImages/>} />
            </Tooltip>
        }
    ];

    // If user has permisison to delete, shows the last column
    if(PERMISSIONS.delete)
        columns.push({
            title: '',
            align: 'center',
            render: (_, record) => <Tooltip title="Eliminar reporte">
                <Popconfirm
                    title="¿Deseas eliminar el reporte?"
                    okText="Eliminar" 
                    cancelText="Cancelar"
                    onConfirm={() => handleDelete(record)} >
                    <Button
                        type="primary"
                        shape="circle" 
                        icon={<FaTrash/>} />
                </Popconfirm>
            </Tooltip>
        });
    
    useEffect(() => {
        async function process(){
            setLoading(true);
            
            try {
                const { data } = await getAllReports(LIMIT, 0);
                reportsContext.updateReports(data.reports);
            } finally{
                setLoading(false);
            }
        }

        process();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const tmp = {
            categories: getUniques(reportsContext.items, "category")
                            .filter(c => c).map(c => ({ value: c, text: c }))
        }
        
        setFilters(tmp);
    }, [reportsContext])

    const getUniques = (elements, prop) => [...new Set(elements.map(i => i[prop]))];

    const handleDelete = async ({ id }) => {
        await deleteReport(id);
        const tmp = [...reportsContext.items.filter(r => r.id !== id)]
        reportsContext.updateReports(tmp);
    }

    return <>
        {reportsContext.items.length >= LIMIT && <Alert type="warning" style={{ marginBottom: "15px" }} showIcon closable
            message={<>Se muestran los últimos {reportsContext.items.length} registros.</>}  />}
        <Table
            rowKey={(record) => record.id}
            dataSource={reportsContext.items}
            columns={columns}
            loading={loading}
            pagination={{ hideOnSinglePage: true }} />
    </>
}