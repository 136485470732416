import { E_164_PHONENUMBER } from "./regex";

export function getCountryCode(phonenumber) {
    if(!isE164Format) throw error(phonenumber);
    return phonenumber.slice(-phonenumber.length-10, -10)
}

export function getNumber(phonenumber) {
    if(!isE164Format) throw error(phonenumber);
    return phonenumber.slice(-10);
}

export function isE164Format(phonenumber) {
    return E_164_PHONENUMBER.test(phonenumber);
}

const error = (phonenumber) => new Error(`'${phonenumber}' is not a valid E.164 format international phone number.`);

const phonenumber = { getCountryCode, getNumber, isE164Format };

export default phonenumber;