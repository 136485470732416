import jwtDecode from "jwt-decode";

import config from "../config";
import http from "./httpService";
import {
    clearToken,
    clearRefreshToken,
    getToken,
    setToken,
    setRefreshToken 
} from "./tokenService";

export async function login(username, password) {
    const { data } = await http.post(config.urls.api_login, { username, password });
    setToken(data.token);
    setRefreshToken(data.refreshToken);
}

export function logout() {
    clearToken();
    clearRefreshToken();
}

export function getCurrentUser() {
    const jwt = getToken();
    return jwt ? jwtDecode(jwt): null;
}

// TODO Eliminar este metodo y sus usos y reemplazarlos por el metodo de abajo.
export function isInRoles(user, roles){
    var dataRoles = user ? user['role'] : getCurrentUser().role;
    if(dataRoles.constructor !== Array)
        dataRoles = dataRoles.split(",");
    return roles ? dataRoles.some(role => roles.includes(role)) : true;
}

export function isInRole(role) {
    var user = getCurrentUser();

    if(!user || !role) return false;

    var userRoles = user.role;

    if(userRoles.constructor !== Array)
        userRoles = [user.rolee];

    return userRoles.some(r => r === role);
}

const authService = { 
    login,
    logout,
    getCurrentUser,
    isInRole,
    isInRoles
};

export default authService;