import moment from 'moment';

const VALID_FORMATS = [
    { mask: "YYYY-MM-DD", regex: /^\d{4}-[01]\d-[0-3]\d$/ },
    { mask: "YYYY-MM-DDTHH:mm:ss", regex: /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d$/ },
    { mask: "YYYY-MM-DDTHH:mm:ssZ", regex: /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\dZ$/ },
    { mask: "YYYY-MM-DDTHH:mm:ss.S...SSSSSSSS", regex: /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d.\d{1,8}$/ },
    { mask: "YYYY-MM-DDTHH:mm:ss.S...SSSSSSSSZ", regex: /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d.\d{1,8}Z$/ }
]

const fromUTC = (date, format = "YYYY-MM-DDTHH:mm:ss") => {
    if (!date) throw new Error("Date must not empty");

    // If date is not string or is not a valid datetime in formats
    if(typeof date !== "string" || !VALID_FORMATS.some(f => f.regex.test(date)))
        throw new Error(`Invalid date. Valid formats [ ${VALID_FORMATS.map(f => `'${f.mask}'`).join(', ')} ]`);

    return moment.utc(date).local().format(format);
};

const date = { VALID_FORMATS, fromUTC };

export default date;