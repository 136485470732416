import { useEffect, useState } from "react";

import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Divider, notification, Input, Select, Skeleton } from "antd";

import config from '../config.json';
import { getConfiguration, setConfiguration } from "../services/configurationService";
import { getCountryCode, getNumber } from "../utils/phonenumber";
import { TEN_DIGITS_PATTERN } from "../utils/regex";
import Page from "./common/Page";

const { Option } = Select;

export default function Configuration() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [finishLoading, setFinishLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        getConfiguration()
            .then(({ data }) => {
                form.setFieldsValue({
                    prefix: getCountryCode(data.sos.phonenumber),
                    sosNotificationPhonenumber: getNumber(data.sos.phonenumber)
                });
            }).finally(() => {
                setLoading(false);
            });
    }, [form]);

    const formItemLayout = {
        labelCol: {
            sm: { span: 8 },
            md: { span: 6 }
        },
        wrapperCol: {
            sm: { span: 16 },
            md: { span: 18 }
        }
    };

    const tailFormItemLayout = {
        wrapperCol: {
            sm: { span: 16, offset: 8 },
            md: { span: 18, offset: 6 }
        }
    };

    const prefixSelector = <Form.Item name="prefix" noStyle>
        <Select style={{ width: 70 }}>
            {config.coutryPhoneCodes.map(c => <Option key={c} value={c}>{c}</Option>)}
        </Select>
    </Form.Item>

    const handleFinish = async ({ prefix, sosNotificationPhonenumber }) => {
        const newConfig = { sos: { phonenumber: `${prefix}${sosNotificationPhonenumber}` } };

        setFinishLoading(true);

        try {
            await setConfiguration(newConfig);
            notification['success']({ message: "Configuración", description: "La configuración de guardó correctamente." });
        } finally {
            setFinishLoading(false);
        }
    };

    const handleInputChange = ({ target }) => form.setFieldValue("sosNotificationPhonenumber", target.value.replace(/[^0-9]/g, ''));

    return <Page title="Configuración">
        {loading ? <Skeleton active /> : <Form form={form} {...formItemLayout} onFinish={handleFinish}>
            <Divider orientation="left">Notificaciones SOS</Divider>
            <Form.Item
                name="sosNotificationPhonenumber"
                label="Número de teléfono"
                extra="Número de teléfono al que se enviarán SMS cada vez que se genere un SOS."
                rules={[
                    { required: true, message: 'Ingrese un número de teléfono' },
                    { pattern: TEN_DIGITS_PATTERN, message: "Debe ser un número de 10 dígitos" }
                ]}>
                <Input addonBefore={prefixSelector} maxLength={10} style={{ width: 240 }} onChange={handleInputChange} />       
            </Form.Item>
            <Form.Item style={{ marginTop: "15px" }} {...tailFormItemLayout}>
                <Button icon={<SaveOutlined />} loading={finishLoading} type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
        </Form>}
    </Page>;
};