import http from "./httpService";

const endPoint = "/api/v1/Configurations";

export function getConfiguration() {
    return http.get(endPoint);
}

export function setConfiguration(configuration) {
    return http.put(endPoint, configuration);
}

const configurationService = {
    getConfiguration,
    setConfiguration
};

export default configurationService;
