import http from "./httpService";

const endPoint = "/api/v1/Reports";


export function getAllReports(take, skip, category){
    return http.get(`${endPoint}`,{ params: { take: take, skip: skip, category: category } });
}

export function deleteReport(id){
    return http.delete(`${endPoint}/${id}`);
}

const reportsService = { 
    getAllReports,
    deleteReport
};

export default reportsService;