import React, {useState} from 'react';
import { Select, notification } from 'antd';
import sosStatus from "../components/dictionary/sosStatus.json";
import { updateSos } from '../services/sosService';

const { Option } = Select;

function SosStatusSelect(props){
    const[loading, setLoading] = useState(false);

    const handleChange = async (value) => {
        setLoading(true);
        try {
            await updateSos(props.sos.id, {newStatusId: value});
            notification['success']({message: 'Reporte SOS', description: 'Se actualizó el estatus.', });
        } finally {
            setLoading(false);
        }
    };

    return <Select
        style={{ width: '100%' }}
        defaultValue={props.sos.statusId}
        disabled={props.disabled}
        loading={loading}
        onChange={(e) => handleChange(e)}>
            {sosStatus.map(s => <Option key={s.key} value={s.key}>{s.value}</Option>)}
    </Select>;
}

export default SosStatusSelect;