import React, { Component } from 'react';
import { Row, Col } from 'antd';

import "../components/Home.css"

export default class Home extends Component {

  render () {
    return (
      <Row justify="center" className="home-row">
        <Col xs={{span:20}} sm={{span:16}} md={{span:12}} >
          <div className='home-shadow'>
              <div className='home-container'>
                  <img className="home-logo" alt="logo" src="./assets/images/escudo.png" />
                  
                  <h1>¡Bienvenido a JalosApp!</h1>
              </div>
          </div>
        </Col>
    </Row>
    );
  }
}
