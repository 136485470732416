import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as ImIcons from 'react-icons/im';

import config from "../config";
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import auth from '../services/authService';
import Context from '../context/UserContext';

const Nav = styled.div`
  background-image: linear-gradient(to right, rgba(234,176,195), rgba(188,0,62,1));
  
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: sticky;
  top: 0;
  border-radius: 0px 0px 15px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon2 = styled.div`
  margin-right: 2rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

const SidebarNav = styled.nav`
  
  background-image: linear-gradient(to right, rgba(234,176,195), rgba(188,0,62,1));
  width: 260px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarWrapContent = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const ContentDiv = styled.div`
  display: flex;
`;

const SidebarLink = styled.div`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #9B0335 ;
    border-left: 4px solid #FFFFFF;
    cursor: pointer;
    color: #e1e9fc;
  }

  &:active {
    color: #e1e9fc;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const Sidebar = () => {
  const context = useContext(Context);
  const [sidebar, setSidebar] = useState(false);
  const user = auth.getCurrentUser();

  const handleLogOut = () => {
    auth.logout();
    window.location = config.urls.login;
  };

  const showSidebar = () => setSidebar(!sidebar);
  

  return (
      
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavIcon to='#'>
            <FaIcons.FaBars className='icon-barr' onClick={showSidebar} />
          </NavIcon>
          <ContentDiv>
            <NavIcon2>
              <img style={{height:'15%', position:'fixed', zIndex:'-1', marginRight:'1rem'}} 
              className="layout-logo" alt="logo" src="./assets/images/iconologo.png" />
              <img style={{height:'31px', position:'absolute', zIndex:'1', marginRight:'6rem'}} 
              className="layout-logo" alt="logo" src="./assets/images/jalosappb.png" />

            </NavIcon2>
          </ContentDiv>
          
        </Nav>
        
        <SidebarNav sidebar={sidebar}>
          
          <SidebarWrap>
          <NavIcon to='#'>
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            <SidebarWrapContent className='scroll-nav'>
            {context.user && SidebarData.reduce((p, c, i) => 
              auth.isInRoles(context.user,c.roles) ? [...p, <SubMenu item={c} key={i} showSidebar={showSidebar}/>] : p, []
            )}
            {user && <SidebarLink onClick={handleLogOut}>
                <div>
                  <ImIcons.ImExit/>
                  <SidebarLabel>Salir</SidebarLabel>
                </div>
              </SidebarLink>}
            </SidebarWrapContent>
            
          </SidebarWrap>
          <img className="img-side-bar" alt="logo" src="./assets/images/iconologo.png" />
        </SidebarNav>
      </IconContext.Provider>
  );
};

export default Sidebar;